import React from "react";
import css from "./Hero.module.css";
import HeroImg from "../../assets/hero.png";
import { motion } from "framer-motion";

const Hero = () => {
  const transition = { duration: 3, type: "spring" };
  return (
    <div className={css.container}>
      {/* left side*/}
      <div className={css.h_sides}>
        <span className={css.text1}>Skin Protection at it's best</span>

        <div className={css.text2}>
          <span>Featured Products</span>
          <span>
            {""}
            Seedily say has suitable disposal and boy. Exercise joy man child
            rejoiced
          </span>
        </div>
      </div>

      {/* middle side hero image */}

      <div className={css.wrapper}>
        {/* blueCircle */}
        <motion.div
          initial={{ bottom: "2rem" }}
          whileInView={{ bottom: "0rem" }}
          // className={css.blueCircle}
          transition={transition}
        ></motion.div>

        {/* hero image */}
        <motion.img
          transition={transition}
          initial={{ bottom: "-2rem" }}
          whileInView={{ bottom: "0rem" }}
          src={HeroImg}
          alt=""
          width={600}
        />

        {/* cart  div animating */}
        <motion.div
          transition={transition}
          initial={{ right: "4%" }}
          whileInView={{ right: "2%" }}
          // className={css.cart2}
        >
          <div className={css.signup}>
            <span></span>
            <div></div>
          </div>
        </motion.div>
      </div>

      {/*right side */}
      <div className={css.h_sides}>
        <div className={css.traffic}></div>

        <div className={css.customers}></div>
      </div>
    </div>
  );
};

export default Hero;
